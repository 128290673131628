/**
 * 正则校验
 */
const checkAllNumber = (value) => {
  const reg = /^[0-9]*$/;
  return reg.test(value);
};
// 用户名
export const validatorUserName = (value) => {
  let reg = /^[a-zA-Z0-9]{4,20}$/;
  if (!value) return "请输入用户名";
  if (!reg.test(value)) return "请输入4-20字英文数字组合格式的用户名";
};
// 商户名称
export const validatorMchName = (value) => {
  if (checkAllNumber(value)) return "商户名称不能是纯数字";
  let reg = /^(?!_+$)[\u4e00-\u9fa5_a-zA-Z0-9_]{2,30}$/;
  if (!reg.test(value)) return "请输入2-30字中英数字组合格式的商户名称";
};
// 公司名称
export const validatorCompanyName = (value) => {
  let reg = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,40}$/;
  if (!reg.test(value)) return "请输入2-40字中英数字组合格式的公司名称";
};
// 姓名
export const validatorChineseName = (value) => {
  let reg = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,20}$/;
  if (!reg.test(value)) return "请输入2-20字中英数字组合格式的姓名";
};
// 设备名称
export const validatorDeviceName = (value) => {
  let reg = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,20}$/;
  if (!reg.test(value)) return "请输入2-20字中英文数字组合格式的设备名称";
};
// 详细地址
export const validatorAddress = (value) => {
  let reg = /^[\u4e00-\u9fa5a-zA-Z0-9]{5,50}$/;
  if (!reg.test(value)) return "请输入5-50字中英文数字组合格式的地址";
};
// 身份证号码
export const validatorIdCardNo = (value) => {
  let reg =
    /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X|x])$/;
  if (!reg.test(value)) return "请输入正确格式的二代身份证号码";
};
// 统一社会信用代码
export const validatorSocialCode = (value) => {
  let reg =
    /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
  if (!reg.test(value)) return "请输入正确格式的统一社会信用代码";
};
// 开户许可证账号
export const validatorOpeningLicense = (value) => {
  let reg = /^[a-zA-Z0-9]{1,30}$/;
  if (!reg.test(value)) return "请输入正确格式的开户许可证账号";
};
