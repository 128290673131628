<template>
  <div class="business-bar u-flex u-row-between">
    <div>
      <p class="u-white-color u-flex">
        当前设备已{{ BusinessStatus.find((b) => b.value === status)?.label }}
        <van-icon
          v-if="status === 2"
          class="u-m-r-6 u-m-l-6"
          class-prefix="iconfont"
          name="question"
          size="20"
          color="#ffffff"
          @click="isShowTipsPop = true"
        />
      </p>
      <p
        v-if="status === 2"
        class="u-white-color u-font-12 u-flex van-ellipsis u-m-t-6"
      >
        故障原因：{{ reason || "--" }}
      </p>
    </div>
    <span
      class="bg-white u-flex u-row-center u-col-center u-theme-color open-btn"
      @click="handleOpenBusiness"
    >
      开启营业
    </span>
  </div>
  <van-popup
    v-model:show="isShowTipsPop"
    round
    :lock-scroll="true"
    :close-on-popstate="true"
    teleport="body"
    position="center"
    close-on-click-overlay
    :style="{ width: '87%' }"
  >
    <div class="u-p-24">
      <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
        出现故障后将停止售卖，恢复方法：
      </p>
      <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
        1.余币不足，远程启动测试成功/补币后自动恢复；
      </p>
      <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
        2.其他故障原因可操作开启营业恢复售卖
      </p>
    </div>
  </van-popup>
</template>

<script>
import { defineComponent, ref } from "vue";
import { BusinessStatus } from "../const";
import { Dialog } from "vant";
export default defineComponent({
  name: "BusinessBar",
  props: {
    status: {
      type: Number,
      default: 1,
    },
    reason: {
      type: String,
      default: "",
    },
  },
  emits: ["confirm"],
  setup(props, { emit }) {
    const isShowTipsPop = ref(false);
    const handleOpenBusiness = () => {
      Dialog.confirm({
        title: "温馨提醒",
        message: "确认开启营业？",
        confirmButtonColor: "#1980ff",
      }).then(() => {
        emit("confirm", 1);
      });
    };
    return {
      isShowTipsPop,
      BusinessStatus,
      handleOpenBusiness,
    };
  },
});
</script>

<style lang="scss" scoped>
.business-bar {
  background-color: #027aff;
  font-size: 14px;
  width: 100%;
  padding: 6px 16px;
  border-radius: 12px 12px 0 0;
}
.open-btn {
  padding: 6px 0;
  width: 88px;
  border-radius: 12px;
}
</style>
