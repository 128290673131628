<template>
  <Page is-header>
    <van-notice-bar left-icon="volume-o" text="启动测试不会生成启动记录~" />
    <van-form ref="TestForm" input-align="right">
      <van-field
        v-model="testForm.deviceCode"
        name="deviceCode"
        label="设备编号"
        readonly
        placeholder=""
      />
      <van-field
        v-model="testForm.workNum"
        name="workNum"
        label="启动次数"
        :rules="[{ required: true, message: '请输入启动次数' }]"
      >
        <template #input>
          <van-stepper v-model="testForm.workNum" min="1" max="99" integer />
        </template>
      </van-field>
    </van-form>

    <div class="u-m-t-16 content">
      <van-button
        :loading="loading"
        loading-text="请稍后..."
        round
        type="primary"
        @click="handleStartDevice"
      >
        启动设备
      </van-button>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, reactive, onActivated } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import { testMchDeviceShipmentApi } from "@/api/device";
import { factoryStartTestApi } from "@/api/vendor";
import { isOperator } from "@/hooks/useRole";
export default defineComponent({
  name: "StartTest",
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const TestForm = ref(null);
    const testForm = reactive({
      deviceCode: "",
      deviceId: "",
      workNum: 1,
    });
    onActivated(() => {
      Object.keys(testForm).forEach((k) => {
        testForm[k] = route.query[k] || 1;
      });
    });
    const handleStartDevice = () => {
      console.log("12313", 12313);
      TestForm.value
        .validate()
        .then(async () => {
          loading.value = true;
          try {
            const actions = isOperator()
              ? testMchDeviceShipmentApi
              : factoryStartTestApi;
            const { code } = await actions({
              deviceId: testForm.deviceId,
              workNum: testForm.workNum,
            });
            if (code === 200) {
              loading.value = false;
              Toast("启动成功");
            }
          } catch (error) {
            loading.value = false;
            console.log("error", error);
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    };
    return {
      TestForm,
      loading,
      testForm,
      handleStartDevice,
    };
  },
});
</script>

<style lang="scss" scoped></style>
