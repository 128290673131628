<template>
  <Page is-header>
    <van-form ref="TheForm" validate-trigger="onSubmit">
      <van-field
        v-model="bindForm.imei"
        name="imei"
        label="硬件序列号"
        placeholder="硬件序列号"
        readonly
      />
      <van-field
        v-model="bindForm.qrcodeNum"
        name="qrcodeNum"
        label="支付码"
        placeholder="请扫描支付码"
        readonly
        :right-icon="bindStatus ? '' : 'scan'"
        :rules="[{ required: true, message: '请扫描支付码' }]"
        @clickRightIcon="handleClickScanPayCode"
        @blur="checkQrcode"
      />
      <van-field
        v-model="bindForm.deviceTypeName"
        readonly
        name="deviceTypeName"
        label="设备类型"
        placeholder="扫描支付码自动填入"
        :rules="[{ required: true, message: '请扫描支付码' }]"
      />
      <van-field
        v-if="Role === 'Operator'"
        v-model="bindForm.deviceName"
        name="deviceName"
        label="设备名称"
        placeholder="请输入设备名称"
        :maxlength="20"
        :rules="[
          {
            required: true,
            message: '请输入设备名称',
            trigger: 'onBlur',
            validator: validatorDeviceName,
          },
        ]"
      />
      <!-- 厂商通讯方式 -->
      <!-- <van-field
        v-if="Role === 'Vendor'"
        v-model="bindForm.communicationMethodName"
        is-link
        readonly
        name="communicationMethodName"
        label="通讯方式"
        placeholder="请选择通讯方式"
        :rules="[{ required: true, message: '请选择通讯方式' }]"
        @click="isShowCommunicationPick = true"
      /> -->
      <!-- 厂商协议 -->
      <!-- <van-field
        v-if="Role === 'Vendor' && bindForm.communicationMethod === 2"
        v-model="bindForm.communicationProtocolName"
        is-link
        readonly
        name="communicationProtocolName"
        label="协议类型"
        placeholder="请选择协议类型"
        :rules="[{ required: true, message: '请选择协议类型' }]"
        @click="isShowProtocolPick = true"
      /> -->
      <!-- 厂商主控型号 -->
      <van-field
        v-if="Role === 'Vendor'"
        v-model="bindForm.deviceProductName"
        is-link
        readonly
        name="deviceProductName"
        label="主控型号"
        placeholder="请选择主控型号"
        :rules="[{ required: true, message: '请选择主控型号' }]"
        @click="isShowProduct = true"
      />
      <van-field
        v-if="Role === 'Operator'"
        v-model="bindForm.siteName"
        is-link
        readonly
        name="siteName"
        label="投放场地"
        placeholder="请选择投放场地"
        :rules="[{ required: true, message: '请选择投放场地' }]"
        @click="handleShowPick(1)"
      />
      <van-field
        v-if="Role === 'Operator'"
        v-model="bindForm.sortNoName"
        is-link
        readonly
        name="sortNoName"
        label="设备序号"
        placeholder="请选择设备序号"
        :rules="[{ required: true, message: '请选择设备序号' }]"
        @click="handleShowPick(2)"
      />
    </van-form>

    <div class="u-m-t-16 content">
      <van-button
        :loading="loading"
        loading-text="请稍后..."
        round
        type="primary"
        @click="handleSubmitBind"
      >
        绑定设备
      </van-button>
    </div>

    <!-- 序号 -->
    <SortNo
      v-if="Role === 'Operator'"
      ref="sortNoRef"
      v-model:visible="isShowPick"
      @confirm="handleConfirmNumber"
    />
    <!-- 选择场地 -->
    <PopBottomList
      v-if="Role === 'Operator'"
      v-model:visible="isShowSitePop"
      pop-type="site"
      :multiple="false"
      @save="handleSavePoint"
    />
    <!-- 选择主控型号 -->
    <PopBottomList
      ref="ProductRef"
      v-if="Role === 'Vendor'"
      v-model:visible="isShowProduct"
      pop-type="product"
      :first="false"
      :multiple="false"
      :params="{ deviceType: bindForm.deviceType }"
      @save="handleSaveProduct"
    />
    <!-- 选择通讯方式 -->
    <!-- <van-popup
      v-if="Role === 'Vendor'"
      v-model:show="isShowCommunicationPick"
      position="bottom"
    >
      <van-picker
        :columns="CommunicationTypes"
        @confirm="onConfirmCommunicationTypes"
        @cancel="isShowCommunicationPick = false"
      />
    </van-popup> -->
    <!-- 选择通讯方式 -->
    <!-- <van-popup
      v-if="Role === 'Vendor' && bindForm.communicationMethod === 2"
      v-model:show="isShowProtocolPick"
      position="bottom"
    >
      <van-picker
        :columns="ProtocolTypes"
        @confirm="onConfirmProtocolTypes"
        @cancel="isShowProtocolPick = false"
      />
    </van-popup> -->
  </Page>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onActivated,
  onDeactivated,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useActions } from "@/hooks/useVuex";
import { validatorDeviceName } from "@/hooks/useRules";
import { DeviceTypes } from "../const";
import PopBottomList from "@/components/PopBottomList.vue";
import SortNo from "../components/SortNo.vue";
import { deviceRegisterApi, checkDeviceQrcodeBindApi } from "@/api/device";
import { factoryDeviceRegisterApi, factoryCheckQrcodeApi } from "@/api/vendor";
import { Toast } from "vant";
import { getCurrentRole } from "@/hooks/useRole";
export default defineComponent({
  name: "DeviceBind",
  components: {
    PopBottomList,
    SortNo,
  },
  setup() {
    const route = useRoute();
    const Role = getCurrentRole();
    const bindStatus = ref(0);
    const router = useRouter();
    const { handleScanCode } = useActions("app", ["handleScanCode"]);
    const sortNoRef = ref(null);
    const TheForm = ref(null);
    const ProductRef = ref(null);
    const loading = ref(false);
    const isShowPick = ref(false);
    const isShowProduct = ref(false);
    const isShowSitePop = ref(false);
    // const isShowCommunicationPick = ref(false);
    // const isShowProtocolPick = ref(false);
    const bindForm = reactive({
      imei: "",
      qrcodeNum: "",
      deviceType: "",
      deviceTypeName: "",
      deviceName: "",
      siteId: "",
      siteName: "",
      sortNo: "",
      sortNoName: "",
      // communicationMethod: "",
      // communicationMethodName: "",
      // communicationProtocol: "",
      // communicationProtocolName: "",
      deviceProductName: "",
      deviceProductId: "",
      androidId: "",
      sn: "",
      platformType: "",
    });
    onActivated(() => {
      console.log(bindStatus.value);
      // 清空
      Object.keys(bindForm).forEach((k) => {
        bindForm[k] = "";
      });
      isShowPick.value = false;
      isShowSitePop.value = false;
      bindForm.imei = route.query.imei || "";
      bindStatus.value = Number(route.query.bindStatus);
      // 新增字段
      route.query.androidId &&
        (bindForm.androidId = route.query.androidId || "");
      route.query.sn && (bindForm.sn = route.query.sn || "");
      route.query.platformType &&
        (bindForm.platformType = route.query.platformType || "");
      if (bindStatus.value) {
        // 已经绑定
        bindForm.qrcodeNum = route.query.qrcodeNum;
        bindForm.deviceType = route.query.deviceType;
        bindForm.deviceTypeName = DeviceTypes.find(
          (i) => i.value == route.query.deviceType
        ).text;
      }
    });
    onDeactivated(() => {
      // isShowCommunicationPick.value = false;
      // isShowProtocolPick.value = false;
      isShowProduct.value = false;
    });

    // 扫码结果
    const getScanResult = (longres) => {
      // 如果要校验格式可以在这里判断
      if (!longres || typeof longres !== "string") return false;
      const res = longres.split("/");
      return JSON.parse(
        JSON.stringify(res[res.length - 1]).replace(/[\r\n]/g, "")
      );
    };
    const handleClickScanPayCode = async () => {
      const result = await handleScanCode(getScanResult);
      console.log("扫码扫码", result);
      if (result) {
        bindForm.qrcodeNum = result;
        checkQrcode();
      } else {
        // 扫描错误格式的支付码二维码
        Toast("支付码格式错误，请检查！");
      }
    };

    const handleShowPick = (type) => {
      console.log(type);
      if (type === 1) {
        isShowSitePop.value = true;
        return true;
      }
      if (type === 2) {
        if (!bindForm.siteId) {
          Toast("请先选择投放场地");
          return false;
        }
        isShowPick.value = !isShowPick.value;
        // bindForm.siteId && sortNoRef.value.getSortNoList(bindForm.siteId);
      }
    };
    // const onConfirmCommunicationTypes = (value) => {
    //   console.log("value", value);
    //   bindForm.communicationMethod = value.value;
    //   bindForm.communicationMethodName = value.text;
    //   // 选择脉冲的时候需要将协议类型置空
    //   if (value.value === 1) {
    //     bindForm.communicationProtocol = "";
    //     bindForm.communicationProtocolName = "";
    //   }
    //   isShowCommunicationPick.value = false;
    // };
    // const onConfirmProtocolTypes = (value) => {
    //   console.log("value", value);
    //   bindForm.communicationProtocol = value.value;
    //   bindForm.communicationProtocolName = value.text;
    //   isShowProtocolPick.value = false;
    // };
    const handleSavePoint = (selected) => {
      console.log(selected);
      if (selected[0].siteId === bindForm.siteId) {
        isShowSitePop.value = false;
        return false;
      }
      bindForm.siteName = selected[0].siteName;
      bindForm.siteId = selected[0].siteId;
      bindForm.siteId && sortNoRef.value.getSortNoList(bindForm.siteId);
      bindForm.sortNo = "";
      bindForm.sortNoName = "";
      isShowSitePop.value = false;
    };
    const handleConfirmNumber = (value) => {
      console.log(value);
      if (value === bindForm.sortNo) {
        isShowPick.value = false;
        return false;
      }
      bindForm.sortNo = value;
      bindForm.sortNoName = value + "号机";
      isShowPick.value = false;
    };
    const handleSaveProduct = (selected) => {
      console.log(selected);
      if (selected[0].id === bindForm.deviceProductId) {
        isShowProduct.value = false;
        return false;
      }
      bindForm.deviceProductName = selected[0].productName;
      bindForm.deviceProductId = selected[0].id;
      isShowProduct.value = false;
    };

    // 提交
    const handleSubmitBind = () => {
      console.log(bindForm);
      TheForm.value
        .validate()
        .then(async () => {
          console.log("success", bindForm);
          loading.value = true;
          let Action = null;
          let params = {
            platformType: bindForm.platformType || "NOSCREEN",
            deviceType: bindForm.deviceType,
            imei: bindForm.imei,
            qrcodeNum: bindForm.qrcodeNum,
            androidId: bindForm.androidId,
            sn: bindForm.sn,
          };
          if (Role === "Operator") {
            // 运营商注册
            Action = deviceRegisterApi;
            params = {
              ...params,
              siteId: bindForm.siteId,
              deviceName: bindForm.deviceName,
              sortNo: bindForm.sortNo,
            };
          } else {
            // 厂商注册
            Action = factoryDeviceRegisterApi;
            params = {
              ...params,
              // communicationMethod: bindForm.communicationMethod,
              // communicationProtocol: bindForm.communicationProtocol,
              deviceProductId: bindForm.deviceProductId,
            };
          }
          try {
            const { code, content } = await Action(params);
            if (code === 200) {
              loading.value = false;
              router.replace({
                path: "/device/register/success",
                query: {
                  deviceId: content.deviceId,
                  deviceCode: content.deviceCode,
                },
              });
            }
          } catch (error) {
            console.log("error", error);
            loading.value = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    // 检查支付码是否绑定
    const checkQrcode = async () => {
      if (!bindForm.qrcodeNum) return false;
      if (!bindStatus.value) {
        console.log("checkQrcode");
        try {
          const action =
            Role === "Operator"
              ? checkDeviceQrcodeBindApi
              : factoryCheckQrcodeApi;
          const {
            code,
            content: { bindStatus, deviceType },
          } = await action({
            qrcode: bindForm.qrcodeNum,
          });
          if (code === 200) {
            if (bindStatus) {
              Toast("当前支付码已绑定");
              bindForm.qrcodeNum = "";
              bindForm.deviceTypeName = "";
              bindForm.deviceType = "";
            } else {
              bindForm.deviceType = deviceType;
              bindForm.deviceTypeName = DeviceTypes.find(
                (i) => i.value == deviceType
              ).text;
            }
          }
        } catch (error) {
          console.log("error", error);
          bindForm.qrcodeNum = "";
          bindForm.deviceTypeName = "";
          bindForm.deviceType = "";
        }
      }
    };
    watch(
      () => bindForm.deviceType,
      () => {
        console.log("监听设备类型--------------------", bindForm.deviceType);
        setTimeout(() => {
          bindForm.deviceProductId = "";
          bindForm.deviceProductName = "";
          // 选择机型之后获取主控型号列表
          ProductRef.value.getRemoteListAction();
        }, 100);
      }
    );
    return {
      Role,
      TheForm,
      loading,
      ProductRef,
      // CommunicationTypes,
      // ProtocolTypes,
      isShowPick,
      isShowProduct,
      sortNoRef,
      isShowSitePop,
      // isShowCommunicationPick,
      // isShowProtocolPick,
      bindForm,
      bindStatus,
      checkQrcode,
      handleClickScanPayCode,
      handleShowPick,
      handleSavePoint,
      handleSaveProduct,
      handleSubmitBind,
      handleConfirmNumber,
      // onConfirmCommunicationTypes,
      // onConfirmProtocolTypes,
      validatorDeviceName,
    };
  },
});
</script>

<style lang="scss" scoped></style>
