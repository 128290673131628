<template>
  <van-popup
    :show="visible"
    teleport="body"
    class="pop-bottom"
    position="bottom"
    :lock-scroll="true"
    :close-on-popstate="true"
    :close-on-click-overlay="false"
    @click-overlay="close"
  >
    <!-- 序号 -->
    <div class="number">
      <div class="pick-header u-flex content u-font-15">
        <div class="header-item u-text-left u-content-color" @click="close">
          取消
        </div>
        <div class="header-item u-text-center u-main-color">选择设备序号</div>
        <div class="header-item u-text-right u-theme-color" @click="confirm">
          确认
        </div>
      </div>
      <p class="u-m-t-10 u-m-b-10 u-font-14 u-tips-color content">
        显示在消费者购买页，同一个场地的序号不可重复
      </p>
      <div class="content-box">
        <div v-if="numberList.length" class="pop-content content">
          <div
            v-for="n in numberList"
            :key="n.sortNo"
            class="number-item u-flex u-row-center"
            :class="{
              disabled: n.bindFlag,
              active: active === n.sortNo && !custom,
            }"
            @click="handleCliclkNumber(n)"
          >
            {{ n.sortNo }}
          </div>
          <div class="number-item u-flex u-row-center">
            <input
              v-model="customNumber"
              :maxlength="2"
              placeholder="请输入"
              type="number"
              pattern="\d*"
              @focus="selectCustomNumber"
            />
          </div>
        </div>
        <div v-else class="point-list">
          <van-empty image="search" description="先选择场地或者序号列表为空" />
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { defineComponent, ref, onActivated, onDeactivated } from "vue";
import { Toast } from "vant";
import { getSiteSortNoApi } from "@/api/device";
export default defineComponent({
  name: "Number",
  props: {
    visible: {
      require: true,
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    console.log(props);
    const active = ref(0);
    const custom = ref(false);
    const customNumber = ref("");
    const numberList = ref([]);
    onActivated(() => {
      custom.value = false;
      customNumber.value = "";
    });
    onDeactivated(() => {
      close();
    });

    // 获取序号列表
    const getSortNoList = async (siteId) => {
      console.log("siteId", siteId);
      try {
        const { code, content } = await getSiteSortNoApi({
          siteId,
        });
        if (code === 200) {
          console.log("content", content);
          numberList.value = content;
          active.value = numberList.value.findIndex((i) => !i.bindFlag) + 1;
          customNumber.value = "";
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    // 关闭弹窗
    const close = () => {
      emit("update:visible", false);
    };
    // 确认
    const confirm = () => {
      const DisabledNos = numberList.value
        .filter((b) => b.bindFlag)
        .map((n) => n.sortNo);
      if (DisabledNos) console.log("DisabledNos", DisabledNos);
      if (
        custom.value &&
        (customNumber.value <= 0 || customNumber.value >= 100)
      ) {
        Toast("请输入1-99内正整数");
        return false;
      }
      if (
        (custom.value && !customNumber.value) ||
        (!custom.value && !active.value)
      ) {
        console.log("请填写");
        Toast("请填写序号");
        return false;
      }
      if (custom.value && DisabledNos.includes(customNumber.value)) {
        Toast("请勿输入已使用序号");
        return false;
      }
      emit("confirm", custom.value ? customNumber.value : active.value);
    };
    // 选择号码
    const handleCliclkNumber = (n) => {
      if (n.bindFlag) return false;
      custom.value = false;
      active.value = n.sortNo;
    };
    // 自定义号码
    const selectCustomNumber = () => {
      custom.value = true;
    };
    return {
      custom,
      active,
      numberList,
      customNumber,
      getSortNoList,
      confirm,
      close,
      handleCliclkNumber,
      selectCustomNumber,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-item {
  width: 33.3%;
  flex: 1;
  height: 40px;
  line-height: 40px;
}
.content-box {
  height: 270px;
  overflow-y: scroll;
}
.pop-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  .number-item {
    font-size: 15px;
    height: 36px;
    box-sizing: border-box;
    border: 1px solid #999999;
    border-radius: 2px;
    background-color: #ffffff;
    input {
      border: none;
      width: 100%;
      height: 100%;
      padding: 0 12px;
    }
  }
  .disabled {
    color: rgba(153, 153, 153, 0.6);
    border-color: rgba(153, 153, 153, 0.6);
  }
  .active {
    color: #027aff;
    border-color: #027aff;
    background-color: rgba(2, 122, 255, 0.2);
  }
}
</style>
