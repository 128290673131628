<template>
  <Page is-header>
    <div class="content u-text-center">
      <p class="u-font-18 u-mian-color u-weight-500 u-m-t-32 u-m-b-40">
        请扫描IOT盒子/机器屏幕上的二维码
      </p>
      <van-image
        width="260"
        height="260"
        :src="ImagePrefix + 'AP1560246326630'"
      />
      <div class="u-m-t-32">
        <van-button
          :loading="loading"
          loading-text="请稍后..."
          round
          type="primary"
          @click="handleDealScanResult"
        >
          扫码绑定
        </van-button>
      </div>
      <!-- <p class="u-m-t-28 u-font-17 u-theme-color">联系客服</p> -->
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useActions } from "@/hooks/useVuex";
import { useRouter } from "vue-router";
import { checkDeviceImeiBindApi } from "@/api/device";
import { factoryCheckDeviceBindApi } from "@/api/vendor";
import { Dialog, Toast } from "vant";
import { isOperator, isVendor } from "@/hooks/useRole";
export default defineComponent({
  name: "Scan",
  inject: ["ImagePrefix"],
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const { handleScanCode } = useActions("app", ["handleScanCode"]);
    const getScanResult = (longres) => {
      // imei是15位数字，去除空格和换行符
      console.log("longres", longres);
      console.log("JSON.stringify(longres)", JSON.stringify(longres));
      let res = "";
      if (typeof longres === "string") {
        res = longres.replace(/[\r \n]/g, "");
      } else {
        res = JSON.stringify(longres).replace(/[\r \n]/g, "");
      }
      const parse = JSON.parse(res);
      if (typeof parse === "string") {
        if (!/^\d{15}$/.test(parse)) return false;
      } else if (typeof parse === "object") {
        console.log("这是对象格式");
        if (!/^\d{15}$/.test(parse.imei)) return false;
      }
      console.log("res", res);
      console.log("JSON.parse(res)", parse);
      // if (!/^\d{15}$/.test(JSON.parse(res))) return false;
      return parse;
    };
    const handleDealScanResult = async () => {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
      }, 2000);
      const result = await handleScanCode(getScanResult);
      loading.value = false;
      console.log("扫码结果", result);
      if (result) {
        const checkAction = isOperator()
          ? checkDeviceImeiBindApi
          : factoryCheckDeviceBindApi;
        const imei = typeof result === "object" ? result.imei : result;
        try {
          const { code, content } = await checkAction({
            imei,
          });
          if (code === 200) {
            if (content.bindStatus === 2) {
              // 已绑定运营商
              Dialog({
                message: "设备已绑定运营商，请先解绑后再来绑定吧",
                confirmButtonText: "我知道了",
                confirmButtonColor: "#027AFF",
              });
            } else if (isVendor() && content.bindStatus === 1) {
              // 厂商注册已绑定厂商
              Dialog({
                message: "设备已绑定厂商，请先解绑后再来绑定吧",
                confirmButtonText: "我知道了",
                confirmButtonColor: "#027AFF",
              });
            } else {
              //
              let query = {
                bindStatus: content.bindStatus,
                imei,
              };
              if (typeof result === "object") {
                query = { ...query, ...result };
              }
              if (content.bindStatus === 1) {
                // 已绑定厂商
                query = {
                  ...query,
                  deviceType: content.deviceType || "",
                  qrcodeNum: content.qrcodeNum || "",
                };
              }
              router.push({
                path: "/device/register/bind",
                query,
              });
            }
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // 扫描错误格式的imei二维码
        Toast("格式错误，请检查！");
        console.log("格式错误，请检查！");
      }
    };
    return {
      loading,
      handleDealScanResult,
    };
  },
});
</script>

<style lang="scss" scoped></style>
