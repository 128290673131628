export const StartRecords = [
  // {
  //   label: "启动状态",
  //   type: "tag",
  //   key: "startState",
  //   value: [
  //     {
  //       label: "全部",
  //       value: "",
  //       select: true,
  //     },
  //     {
  //       label: "启动成功",
  //       value: "1",
  //       select: false,
  //     },
  //     {
  //       label: "启动失败",
  //       value: "0",
  //       select: false,
  //     },
  //   ],
  // },
  {
    label: "币消耗状态",
    type: "tag",
    key: "coinState",
    value: [
      {
        label: "全部",
        value: "",
        select: true,
      },
      {
        label: "消费成功",
        value: "1",
        select: false,
      },
      {
        label: "消费失败",
        value: "0",
        select: false,
      },
      {
        label: "退币成功",
        value: "2",
        select: false,
      },
    ],
  },
  // {
  //   label: "礼品是否出货",
  //   type: "tag",
  //   key: "hitGift",
  //   value: [
  //     {
  //       label: "全部",
  //       value: "",
  //       select: true,
  //     },
  //     {
  //       label: "已命中",
  //       value: "1",
  //       select: false,
  //     },
  //     {
  //       label: "未命中",
  //       value: "0",
  //       select: false,
  //     },
  //     {
  //       label: "未知",
  //       value: "2",
  //       select: false,
  //     },
  //   ],
  // },
  {
    label: "币类型",
    type: "tag",
    key: "coinType",
    value: [
      {
        label: "全部",
        value: "",
        select: true,
      },
      {
        label: "实体币",
        value: "2",
        select: false,
      },
      {
        label: "线上币",
        value: "1",
        select: false,
      },
    ],
  },
];
