<template>
  <Page is-header>
    <van-form ref="InfoForm" validate-trigger="onSubmit">
      <van-field
        v-model="updateForm.deviceName"
        name="deviceName"
        label="设备名称"
        placeholder="请输入设备名称"
        :maxlength="20"
        :rules="[
          {
            required: true,
            message: '请输入设备名称',
            trigger: 'onBlur',
            validator: validatorDeviceName,
          },
        ]"
      />
      <van-field
        v-model="updateForm.siteName"
        is-link
        readonly
        name="siteName"
        label="投放场地"
        placeholder="点击选择投放场地"
        :rules="[{ required: true, message: '请选择投放场地' }]"
        @click="handleShowPick('isShowSitePop')"
      />
      <van-field
        v-model="updateForm.sortNoName"
        is-link
        readonly
        name="sortNoName"
        label="设备序号"
        placeholder="点击选择设备序号"
        :rules="[{ required: true, message: '请选择设备序号' }]"
        @click="handleShowPick('isShowNoPop')"
      />
    </van-form>

    <div class="u-m-t-16 content">
      <van-button
        :loading="loading"
        loading-text="请稍后..."
        round
        type="primary"
        @click="handleSubmitSave"
      >
        保存
      </van-button>
    </div>
    <!-- 序号 -->
    <SortNo
      ref="sortNoRef"
      v-model:visible="isShowNoPop"
      @confirm="handleConfirmNumber"
    />
    <!-- 选择场地 -->
    <PopBottomList
      v-model:visible="isShowSitePop"
      pop-type="site"
      :default-value="[updateForm.siteId]"
      :multiple="false"
      @save="handleSavePoint"
    />
  </Page>
</template>

<script>
import { defineComponent, ref, reactive, onActivated } from "vue";
import { useRoute, useRouter } from "vue-router";
import PopBottomList from "@/components/PopBottomList.vue";
import SortNo from "../components/SortNo.vue";
import { validatorDeviceName } from "@/hooks/useRules";
import { updateDeviceInfoApi } from "@/api/device";
import { Toast } from "vant";
export default defineComponent({
  name: "DeviceInfo",
  components: {
    PopBottomList,
    SortNo,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isShowNoPop = ref(false);
    const isShowSitePop = ref(false);
    const loading = ref(false);
    const InfoForm = ref(null);
    const sortNoRef = ref(null);
    const updateForm = reactive({
      deviceName: "",
      siteName: "",
      siteId: "",
      sortNo: "",
      sortNoName: "",
    });
    onActivated(() => {
      Object.keys(updateForm).forEach((k) => {
        if (k === "sortNoName") {
          updateForm.sortNoName = route.query["sortNo"] + "号机";
        } else {
          updateForm[k] = route.query[k] || "";
        }
      });
    });
    const handleShowPick = (type) => {
      console.log("type", type);
      // type["value"] = true;
      type === "isShowSitePop" && (isShowSitePop.value = true);
      type === "isShowNoPop" && (isShowNoPop.value = true);
      sortNoRef.value.getSortNoList(updateForm.siteId);
    };
    const handleConfirmNumber = (value) => {
      console.log(value);
      updateForm.sortNo = value;
      updateForm.sortNoName = value + "号机";
      isShowNoPop.value = false;
    };
    const handleSavePoint = (selected) => {
      console.log(selected);
      sortNoRef.value.getSortNoList(updateForm.siteId);
      updateForm.siteId = selected[0].siteId;
      updateForm.siteName = selected[0].siteName;
      updateForm.sortNo = "";
      updateForm.sortNoName = "";
      isShowSitePop.value = false;
    };
    const handleSubmitSave = () => {
      InfoForm.value
        .validate()
        .then(async () => {
          loading.value = true;
          try {
            const { code } = await updateDeviceInfoApi({
              deviceId: route.query.deviceId,
              deviceName: updateForm.deviceName,
              siteId: updateForm.siteId,
              sortNo: updateForm.sortNo,
            });
            if (code === 200) {
              loading.value = false;
              Toast("修改成功");
              router.back();
            }
          } catch (error) {
            loading.value = false;
            console.log("error", error);
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    };
    return {
      InfoForm,
      sortNoRef,
      loading,
      updateForm,
      isShowNoPop,
      isShowSitePop,
      handleShowPick,
      handleConfirmNumber,
      handleSavePoint,
      handleSubmitSave,
      validatorDeviceName,
    };
  },
});
</script>

<style lang="scss" scoped></style>
