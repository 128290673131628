<template>
  <Page is-header>
    <van-sticky offset-top="1.174rem">
      <!-- 日期选择 -->
      <TimePick @change="handleTimeChange" />
      <!-- 搜索框 -->
      <Search
        v-model="searchText"
        placeholder="请输入设备编号或名称搜索"
        :show-action="true"
        @on-search="handleSearch"
      >
        <template #action>
          <i
            class="iconfont iconfont-shaixuan"
            @click="isShowPopRight = true"
          />
        </template>
      </Search>
    </van-sticky>

    <div class="order-list-box content">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getRecordList"
      >
        <template v-if="recordList.length">
          <template v-for="record in recordList" :key="record.searchText">
            <CoinItem :record-info="record" />
          </template>
        </template>
        <template v-else>
          <van-empty image="search" description="" />
        </template>
      </van-list>
    </div>
    <!-- 筛选 -->
    <PopRight
      v-model:visible="isShowPopRight"
      dom-key="record"
      @action="handleFilterAction"
    >
      <template #content>
        <div class="filter-box">
          <div
            v-for="(item, index) in filters"
            :key="item.label"
            class="filter-item"
          >
            <p class="filter-item-label">{{ item.label }}</p>
            <ul
              v-if="item.type === 'tag'"
              class="filter-item-value u-flex u-flex-wrap"
            >
              <li
                v-for="tag in item.value"
                :key="tag.value"
                class="filter-item-tag u-flex u-row-center"
                :class="{ selected: tag.select }"
                @click="handleSelectTag(index, tag)"
              >
                {{ tag.label }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </PopRight>
  </Page>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  toRefs,
  onActivated,
} from "vue";
import TimePick from "@/components/TimePick";
import Search from "@/components/Search";
import PopRight from "@/components/PopRight";
import CoinItem from "./components/CoinItem.vue";
import { StartRecords } from "./const";
import { useRoute } from "vue-router";
import { getStartRecordByPageApi } from "@/api/order";
import moment from "moment";
export default defineComponent({
  name: "CoinRecord",
  components: {
    TimePick,
    Search,
    PopRight,
    CoinItem,
  },
  setup() {
    const route = useRoute();
    const recordList = ref([]);
    const state = reactive({
      page: 1,
      pageSize: 10,
      totalSize: 0,
      loading: false,
      finished: false,
      currentDate: moment(new Date()).format("YYYY-MM-DD"),
    });
    const isShowPopRight = ref(false);
    const searchText = ref("");
    // 筛选条件
    const filters = reactive(StartRecords);
    // 回显参数
    onActivated(() => {
      console.log("父组件onActivated");
      searchText.value = route.query.deviceCode;
      handleSearch();
    });
    const handleSearch = () => {
      console.log(123123);
      state.page = 1;
      getRecordList();
    };
    // 请求列表接口
    const getRecordList = async () => {
      try {
        const {
          code,
          content: { currentList, totalSize },
        } = await getStartRecordByPageApi({
          beginTime: state.currentDate + " 00:00:00",
          endTime: state.currentDate + " 23:59:59",
          page: state.page,
          pageSize: 10,
          searchText: searchText.value,
          ...filterQuery.value,
        });
        if (code === 200) {
          // console.log("content", content);
          if (state.page === 1) {
            recordList.value = currentList;
          } else {
            recordList.value = recordList.value.concat(currentList);
          }
          state.finished = Math.ceil(totalSize / 10) <= state.page;
          state.page++;
          state.loading = false;
        }
      } catch (error) {
        console.log("error", error);
        state.loading = false;
      }
    };
    const handleTimeChange = (time) => {
      console.log(time);
      state.currentDate = time;
      handleSearch();
    };
    // 选择条件
    const handleSelectTag = (idx, tag) => {
      filters[idx].value?.forEach((v) => {
        if (v.value === tag.value) {
          v.select = true;
        } else {
          v.select = false;
        }
      });
    };
    // 重置
    const reset = async () => {
      filters.forEach((item) => {
        if (item.type === "tag") {
          item.value.forEach((v, i) => {
            if (i) {
              v.select = false;
            } else {
              v.select = true;
            }
          });
        }
      });
      return Promise.resolve(true);
    };
    // 计算筛选条件
    const filterQuery = computed(() => {
      let result = {};
      filters.forEach((item) => {
        if (item.type === "tag") {
          result[item.key] = item.value.find((tag) => tag.select).value;
        }
      });
      return result;
    });
    const handleFilterAction = async (type) => {
      if (type < 0) {
        // 重置
        await reset();
        handleSearch();
      } else {
        // 确定
        handleSearch();
      }
      isShowPopRight.value = false;
    };
    return {
      ...toRefs(state),
      isShowPopRight,
      searchText,
      filters,
      recordList,
      filterQuery,
      handleSearch,
      getRecordList,
      handleTimeChange,
      handleSelectTag,
      handleFilterAction,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-item-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 15px;
}
.filter-item-value {
  padding-bottom: 12px;
  :deep(.van-search) {
    padding: 0;
  }
}
.filter-item-tag {
  min-width: 74px;
  height: 32px;
  background-color: #f0f2f5;
  border-radius: 4px;
  color: #373838;
  margin: 0 10px 8px 0;
  font-size: 14px;
  padding: 0 6px;
  box-sizing: border-box;
}
.selected {
  background-color: rgba(2, 122, 255, 0.2);
  color: #027aff;
}
</style>
