<template>
  <Page is-header>
    <van-sticky offset-top="1.174rem">
      <van-notice-bar
        left-icon="volume-o"
        text="请谨慎选择主控型号，型号错误会导致设备无法运行~"
      />
      <!-- 搜索框 -->
      <Search
        v-model.trim="searchKey"
        placeholder="请输入主控型号名称搜索"
        :show-action="true"
        @on-search="handleSearch"
      >
        <template #action>
          <span class="u-font-14 u-theme-color" @click="handleSearch"
            >搜索</span
          >
        </template>
      </Search>
    </van-sticky>

    <ul v-if="productList.length" class="u-p-b-80">
      <div v-if="loading" class="van-picker__loading">
        <van-loading type="spinner" size="42" />
      </div>
      <li
        v-for="item in productList"
        :key="item.id"
        class="u-flex u-p-12 u-font-14 bg-white van-hairline--top"
        @click="handleSelectItem(item)"
      >
        <div class="u-flex">
          <van-icon
            v-if="item.select"
            class-prefix="iconfont"
            name="success"
            size="20"
            color="#027AFF"
          />
          <van-icon
            v-else
            class-prefix="iconfont"
            name="circle"
            color="#999999"
            size="20"
          />
        </div>
        <div class="u-flex-1 u-m-l-10 u-flex u-row-between u-line-1">
          <div class="u-flex-1 u-line-1">
            {{ item.productName || "--" }}
          </div>
          <span
            v-if="item.productImage"
            class="u-theme-color u-font-14"
            @click="imagePreviewer(item.productImage)"
            >主板图</span
          >
        </div>
      </li>
    </ul>
    <div v-else>
      <div v-if="loading" class="van-picker__loading">
        <van-loading type="spinner" size="42" />
      </div>
      <van-empty image="search" description="暂无主控型号可选择" />
    </div>
    <div class="fixed-bottom-btn content">
      <van-button round type="primary" @click="handleConfirmSubmit">
        确定
      </van-button>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, computed, inject, onActivated } from "vue";
import { factoryGetDeviceProductApi } from "@/api/vendor";
import { imagePreviewer } from "@/hooks/useImagePreview";
import Search from "@/components/Search.vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { findIndex } from "lodash";
import { factoryUpdateProductApi } from "@/api/vendor";
export default defineComponent({
  name: "MainControl",
  components: {
    Search,
  },
  setup() {
    const router = useRouter();
    const ImagePrefix = inject("ImagePrefix");
    const searchKey = ref("");
    const loading = ref(false);
    const deviceId = ref("");
    const deviceType = ref("");
    const productName = ref("");
    let productList = ref([]);

    onActivated(() => {
      const { currentRoute } = useRouter();
      const {
        deviceId: dId,
        productName: pName,
        deviceType: dType,
      } = currentRoute.value.query;
      deviceId.value = dId || "";
      deviceType.value = dType || "";
      productName.value = pName || "";
      searchKey.value = "";
      getRemoteListAction();
    });

    // 已选中
    const selected = computed(() => {
      return productList.value.filter((i) => i.select);
    });
    // 请求接口方法
    const getRemoteListAction = async () => {
      loading.value = true;
      try {
        const { code, content } = await factoryGetDeviceProductApi({
          productName: searchKey.value,
          deviceType: deviceType.value,
        });
        if (code === 200) {
          const list = Array.isArray(content) ? content : content.currentList;
          const idx = findIndex(list, { productName: productName.value });
          let m = list.map((l, i) => {
            if (l.productImage) {
              let productImages = l.productImage.split(",") || [];
              l.productImage = productImages.map((m) => {
                return `${ImagePrefix}${m}`;
              });
            }
            return {
              ...l,
              select: idx >= 0 ? idx === i : !i,
            };
          });
          productList.value = m;
          loading.value = false;
        }
      } catch (error) {
        console.log("error", error);
        loading.value = false;
      }
    };
    // 搜索
    const handleSearch = () => {
      getRemoteListAction();
    };
    // 选中某个选项
    const handleSelectItem = (item) => {
      productList.value.forEach((p) => {
        if (item["id"] === p["id"]) {
          p.select = true;
        } else {
          p.select = false;
        }
      });
    };
    // 提交
    const handleConfirmSubmit = async () => {
      console.log("selected.value", selected.value);
      try {
        const { code } = await factoryUpdateProductApi({
          deviceId: deviceId.value,
          deviceProductId: selected.value[0].id,
        });
        if (code === 200) {
          Toast("更换成功");
          router.back();
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    return {
      searchKey,
      loading,
      productList,
      imagePreviewer,
      handleSearch,
      handleSelectItem,
      handleConfirmSubmit,
    };
  },
});
</script>

<style lang="scss" scoped></style>
