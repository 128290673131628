<template>
  <Page is-header>
    <div class="u-p-24 bg-white u-text-center">
      <p>
        <van-icon
          class-prefix="iconfont"
          name="success"
          color="#28CE3E"
          size="48"
        />
      </p>
      <p class="u-main-color u-font-22 u-p-t-12 u-weight-500">注册成功</p>
      <p class="u-content-color u-font-16 u-p-t-16 success-text u-text-left">
        设备编号{{ deviceCode }}，注册成功后可直接开机交易
      </p>
    </div>
    <div class="u-flex u-m-t-32 content">
      <van-button plain round type="primary" @click="handleToDetail">
        {{ Role === "Operator" ? "进入设备详情" : "返回首页" }}
      </van-button>
      <div style="width: 40px" />
      <van-button round type="primary" @click="handleToScan">
        继续注册
      </van-button>
    </div>
  </Page>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getCurrentRole } from "@/hooks/useRole";
export default defineComponent({
  name: "BindSuccess",
  setup() {
    const router = useRouter();
    const Role = getCurrentRole();
    const state = reactive({
      deviceId: "",
      deviceCode: "",
    });
    const route = useRoute();
    state.deviceId = route.query.deviceId;
    state.deviceCode = route.query.deviceCode;

    const handleToDetail = () => {
      if (Role === "Operator") {
        router.replace({
          path: "/device/detail/operator",
          query: {
            deviceId: state.deviceId,
          },
        });
      } else {
        router.replace({
          path: "/home/index",
        });
      }
    };
    const handleToScan = () => {
      router.go(-1);
    };
    return {
      Role,
      ...toRefs(state),
      handleToDetail,
      handleToScan,
    };
  },
});
</script>

<style lang="scss" scoped>
.success-text {
  line-height: 24px;
}
</style>
