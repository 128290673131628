<template>
  <Page is-header>
    <van-tabs
      v-if="configList.length"
      v-model:active="tabActive"
      color="#027AFF"
      line-height="2px"
    >
      <van-tab
        :title="tab.executeName"
        v-for="tab in configList"
        :key="tab.executeCode"
      >
        <van-form ref="TestForm" input-align="right">
          <van-field
            v-model="deviceCode"
            name="deviceCode"
            label="设备编号"
            readonly
            placeholder=""
          />
          <template v-for="(config, idx) in tab.configs" :key="config.id">
            <!-- 步进器 -->
            <van-field
              v-if="['int', 'double'].includes(config.fieldType)"
              v-model="config.setValue"
              :name="config.id"
              :label="formatLabel(config)"
            >
              <template #input>
                <van-stepper
                  v-model="config.setValue"
                  :min="config.min"
                  :max="config.max"
                  :integer="config.fieldType === 'int'"
                  :step="config.valueOption"
                  input-width="52px"
                />
              </template>
            </van-field>
            <!-- 输入框 -->
            <van-field
              v-if="['string'].includes(config.fieldType)"
              v-model="config.setValue"
              :name="config.id"
              :label="config.paramName"
            >
              <template #input>
                <van-field
                  v-model="config.setValue"
                  label=""
                  :placeholder="config.remark"
                />
              </template>
            </van-field>
            <!-- 开关 -->
            <van-field
              v-if="['boolean'].includes(config.fieldType)"
              :name="config.id"
              :label="config.paramName"
            >
              <template #input>
                <van-switch v-model="config.setValue" size="20" />
              </template>
            </van-field>
            <!-- 选择器 -->
            <van-field
              v-if="['enum'].includes(config.fieldType)"
              :name="config.id"
              v-model="config.setValueText"
              readonly
              is-link
              :label="config.paramName"
              @click="handleChooseOption(config, idx)"
            />
          </template>
        </van-form>
        <div class="u-m-t-16 content u-m-b-32">
          <van-button
            :loading="loading"
            loading-text="请稍后..."
            round
            type="primary"
            @click="handleSettingSave"
          >
            设置
          </van-button>
        </div>
      </van-tab>
    </van-tabs>
    <van-empty v-else image="error" description="设备不支持设置">
      <p class="u-tips-color">请联系工厂了解支持设置的机型</p>
    </van-empty>
    <van-popup round v-model:show="isShowSheet" position="bottom">
      <van-picker
        :columns="actionSheetList"
        @confirm="handleConfirmOption"
        @cancel="isShowSheet = false"
      />
    </van-popup>
  </Page>
</template>

<script>
import { defineComponent, ref, onActivated } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import {
  getMchDeviceConfigListApi,
  updateMchDeviceConfigListApi,
} from "@/api/device";
import { isOperator } from "@/hooks/useRole";
import {
  factoryGetDeviceConfigApi,
  factoryUpdateDeviceConfigApi,
} from "@/api/vendor";
export default defineComponent({
  name: "DeviceHardware",
  setup() {
    const route = useRoute();
    const deviceCode = ref("");
    const deviceId = ref("");
    const isShowSheet = ref(false);
    const loading = ref(false);
    const actionSheetList = ref([]);
    const configList = ref([]);
    const tabActive = ref(0);
    const enumIdx = ref(0);
    onActivated(() => {
      tabActive.value = 0;
      deviceCode.value = route.query.deviceCode || "";
      deviceId.value = route.query.deviceId || "";
      getConfigList();
    });
    // 计算单位
    const formatLabel = (config) => {
      if (config.valueUnit) {
        return config.paramName + "(" + config.valueUnit + ")";
      } else {
        return config.paramName;
      }
    };
    // 获取配置列表
    const getConfigList = async () => {
      try {
        const actions = isOperator()
          ? getMchDeviceConfigListApi
          : factoryGetDeviceConfigApi;
        const { code, content } = await actions({
          deviceId: deviceId.value,
        });
        if (code === 200) {
          console.log("content", content);
          if (content) {
            content.forEach((t) => {
              t.configs &&
                t.configs.forEach((k) => {
                  if (k.numRange && k.numRange.indexOf("-") > -1) {
                    const range = k.numRange.split("-");
                    k.min = range[0];
                    k.max = range[1];
                  }
                  if (k.fieldType === "enum") {
                    k.valueOption = JSON.parse(
                      k.valueOption || JSON.stringify([])
                    );
                    k.setValueText = (
                      k.valueOption.find((n) => n.value == k.setValue) || {
                        text: "",
                      }
                    ).text;
                  }
                  if (k.fieldType === "boolean") {
                    k.setValue = Boolean(Number(k.setValue));
                  }
                });
            });
          }
          configList.value = content || [];
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    // 选择
    const handleChooseOption = (config, idx) => {
      console.log("config", config);
      enumIdx.value = idx;
      actionSheetList.value = config.valueOption || [];
      isShowSheet.value = true;
    };
    // 确认选择
    const handleConfirmOption = (value) => {
      console.log("value", value);
      configList.value[tabActive.value]["configs"][enumIdx.value].setValue =
        value.value;
      configList.value[tabActive.value]["configs"][enumIdx.value].setValueText =
        value.text;
      isShowSheet.value = false;
    };
    const handleSettingSave = async () => {
      loading.value = true;
      const configs = configList.value[tabActive.value].configs.map((i) => {
        return {
          id: i.id,
          setValue:
            typeof i.setValue === "boolean" ? Number(i.setValue) : i.setValue,
        };
      });
      try {
        const actions = isOperator()
          ? updateMchDeviceConfigListApi
          : factoryUpdateDeviceConfigApi;
        const { code } = await actions({
          deviceId: deviceId.value,
          executeCode: configList.value[tabActive.value].executeCode,
          configs,
        });
        if (code === 200) {
          loading.value = false;
          Toast("设置成功");
        }
      } catch (error) {
        loading.value = false;
        console.log("error", error);
      }
    };
    return {
      tabActive,
      deviceCode,
      isShowSheet,
      actionSheetList,
      loading,
      configList,
      formatLabel,
      handleChooseOption,
      handleConfirmOption,
      handleSettingSave,
    };
  },
});
</script>

<style lang="scss" scoped></style>
