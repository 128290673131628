<template>
  <Page is-header>
    <div v-if="deviceDetail" class="u-p-14 u-flex bg-white u-col-top">
      <van-image radius="4" width="72px" height="72px" :src="deviceImg" />
      <div class="u-flex-1 u-m-l-12">
        <div class="info-top u-flex">
          <!-- 信号 -->
          <SignalIcon
            :online="deviceDetail.onlineState"
            :signal="deviceDetail.networkSignal"
          />
          <div class="u-m-l-6">
            <Tag :text="['', '娃', '兑', '艺'][deviceDetail.deviceType]" />
          </div>
          <div class="u-m-l-6 u-m-r-6">
            <Tag :text="deviceDetail.sortNo" />
          </div>
          <span>{{ deviceDetail.deviceCode }}</span>
        </div>
        <p class="info-name u-main-color u-font-18 u-weight-500 u-line-1">
          {{ deviceDetail.deviceName }}
        </p>
        <p class="info-top u-tips-color u-line-2 u-font-14">
          投放场地名称：{{ deviceDetail.siteName }}
        </p>
      </div>
    </div>
    <!-- 设备状态 -->
    <transition name="van-fade">
      <div
        v-if="[0, 2].includes(deviceDetail.isSell)"
        class="u-p-l-12 u-p-r-12 bg-white"
      >
        <BusinessBar
          :status="deviceDetail.isSell"
          :reason="['', '', '余币不足'][deviceDetail.isSell]"
          @confirm="handleConfirmSubmit"
        />
      </div>
    </transition>

    <!-- 全部功能 -->
    <div class="bg-white u-m-t-12 u-p-t-12 u-p-b-12">
      <p class="u-main-color u-weight-500 u-p-l-12">全部功能</p>
      <div class="u-flex u-flex-wrap">
        <div
          v-for="menu in Menus"
          :key="menu.path"
          class="menu-item u-flex u-flex-column u-m-t-16"
          @click="handleClickMenu(menu)"
        >
          <van-icon
            class-prefix="iconfont"
            :name="menu.icon"
            size="32"
            :color="menu.color"
          />
          <span class="u-main-color u-font-13 u-content-color u-m-t-10">
            {{ menu.name }}
          </span>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <van-dialog
      v-model:show="isShowDialog"
      :title="dialogTitle"
      confirm-button-color="#027AFF"
      :before-close="handleDialogClose"
      show-cancel-button
    >
      <div class="dialog-box">
        <div v-if="dialogTitle === '启动单价'" class="u-flex u-row-center">
          <span>启动需</span>
          <div class="price-input van-hairline--surround u-radius-2">
            <input v-model="workCoin" :maxlength="2" placeholder="请输入" />
          </div>
          <span>币/次</span>
        </div>
        <div v-else class="u-flex u-row-center">
          解绑后设备可被其他商户绑定~
        </div>
      </div>
    </van-dialog>
    <!-- 二维码 -->
    <van-popup
      v-model:show="isShowPop"
      teleport="body"
      :lock-scroll="true"
      :close-on-popstate="true"
      round
    >
      <div class="pop-content u-text-center">
        <div
          ref="qrCode"
          class="qrcode qrcode-img u-flex u-row-center"
          :style="{
            display: qrcodeAndriodImg ? 'none' : '',
          }"
        />
        <div v-if="qrcodeAndriodImg" class="qrcode-img">
          <van-image width="200px" height="200px" :src="qrcodeAndriodImg" />
        </div>
        <!-- <van-button type="primary" round @click="downloadQrcode"
          >下载二维码</van-button
        > -->
        <div
          class="u-font-16 u-tips-color u-text-center u-line-height-22 u-m-t-20 u-m-b-30"
        >
          长按二维码识别或者保存图片
        </div>
      </div>
    </van-popup>
    <van-action-sheet
      v-model:show="isShowAutoCionPop"
      :actions="actions"
      cancel-text="取消"
      :before-close="handleAutoCionPopClose"
      @select="handleAutoCionPopSelect"
      @open="handleAutoCionOpen"
    />

    <!-- 营业状态 -->
    <Pick
      v-model:visible="isShowBussinessPop"
      header
      title="设置营业状态"
      @confirm="handleConfirmSubmit"
    >
      <ul class="bussiness-pop-content">
        <li
          v-for="item in businessStatus"
          :key="item.value"
          class="u-flex u-p-12 u-font-14 bg-white van-hairline--top"
          @click="handleSelectItem(item)"
        >
          <div class="u-flex">
            <van-icon
              v-if="item.select"
              class-prefix="iconfont"
              name="success"
              size="20"
              color="#027AFF"
            />
            <van-icon
              v-else
              class-prefix="iconfont"
              name="circle"
              color="#999999"
              size="20"
            />
          </div>
          <div class="u-flex-1 u-m-l-10 u-flex u-row-between u-line-1">
            <div class="u-flex-1 u-line-1">
              {{ item.label || '--' }}
            </div>
          </div>
        </li>
      </ul>
    </Pick>
  </Page>
</template>

<script>
import {
  defineComponent,
  ref,
  onActivated,
  nextTick,
  computed,
  onDeactivated,
  reactive,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DeviceDetailMenus, BusinessStatus } from '../const';
import { useActions, useGetters } from '@/hooks/useVuex';
import Tag from '@/components/Tag.vue';
import Pick from '@/components/Pick';
import SignalIcon from '../components/SignalIcon.vue';
import QRCode from 'qrcodejs2';
import {
  updateDeviceStartCoinApi,
  unBindMchDeviceApi,
  updateDeviceStateApi,
  updateDeviceSoftApi,
} from '@/api/device';
import { Toast } from 'vant';
import BusinessBar from '../components/BusinessBar.vue';
import { cloneDeep } from 'lodash';
export default defineComponent({
  name: 'DeviceDetail',
  components: {
    Tag,
    SignalIcon,
    BusinessBar,
    Pick,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const deviceId = ref('');
    const dialogTitle = ref('');
    const workCoin = ref('1');
    const qrCode = ref(null);
    const isShowDialog = ref(false);
    const isShowBussinessPop = ref(false);
    const isShowPop = ref(false);
    const isShowAutoCionPop = ref(false);
    const actions = ref([
      { name: '开启', value: '1', color: '' },
      { name: '关闭', value: '0', color: '' },
    ]);
    console.log(isShowAutoCionPop);
    const qrcodeAndriodImg = ref('');
    const businessStatus = reactive(
      cloneDeep(BusinessStatus).filter((item) => item.value !== 2)
    );
    const { getDeviceDetailAction } = useActions('device', [
      'getDeviceDetailAction',
    ]);
    const { deviceDetail } = useGetters('device', ['deviceDetail']);
    // 请求
    onActivated(() => {
      isShowPop.value = false;
      deviceId.value = route.query.deviceId;
      handleGetDeviceInfo();
    });
    onDeactivated(() => {
      isShowPop.value = false;
    });
    const handleAutoCionPopClose = () => {
      // isShowAutoCionPop.value = false;
      updateDeviceSoftApi;
    };
    const handleAutoCionOpen = () => {
      console.log(deviceDetail.value);
      console.log('11', deviceDetail.value.autoCoin == 1);
      actions.value = [
        {
          name: '开启',
          value: '1',
          color: deviceDetail.value.autoCoin == '1' ? '#1980ff' : '',
        },
        {
          name: '关闭',
          value: '0',
          color: deviceDetail.value.autoCoin == '1' ? '' : '#1980ff',
        },
      ];
      console.log('actions', actions.value);
    };
    const handleAutoCionPopSelect = async (item) => {
      try {
        const { code } = await updateDeviceSoftApi({
          deviceId: deviceId.value,
          multiConfigBOS: [{ type: 'AUTO_COIN', value: item.value }],
        });
        if (code == 200) {
          Toast('设置成功');
          deviceDetail.value.autoCoin = item.value;
          actions.value.forEach((i) => {
            if (i.value == item.value) {
              i.color = '#1980ff';
            } else {
              i.color = '';
            }
          });
          isShowAutoCionPop.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    };
    // 设备图片
    const deviceImg = computed(() => {
      return (
        deviceDetail.value &&
        deviceDetail.value.deviceType &&
        require(`@/assets/images/ic_device_${deviceDetail.value.deviceType}.png`)
      );
    });
    // 已选中
    const selectedBusiness = computed(() => {
      return businessStatus.filter((i) => i.select);
    });
    // 动态生成菜单
    const Menus = computed(() => {
      return DeviceDetailMenus.filter((m) => m.show(deviceDetail.value));
    });
    // 前端生成二维码
    const createQrcode = () => {
      console.log('createQrcode');
      qrCode.value && (qrCode.value.innerHTML = ''); // 创建之前需要把上次创建的删除
      new QRCode(qrCode.value, {
        width: 200,
        height: 200,
        text: deviceDetail.value.miniQrcodeUrl,
      });
      // 加个延迟，不然display一直是空
      setTimeout(() => {
        const Canvas = document.getElementsByTagName('canvas')[0];
        const display = Canvas.style.display;
        if (display === 'none') return false;
        const AndriodImgUrl = Canvas.toDataURL('image/png');
        qrcodeAndriodImg.value = AndriodImgUrl;
      }, 100);
    };
    // 下载二维码
    const downloadQrcode = () => {
      console.log('qrCode.value', qrCode.value.lastChild.src);
      downloadByBlob(qrCode.value.lastChild.src, '二维码');
    };
    // 下载图片
    const downloadByBlob = (url, name) => {
      let image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          downloadImg(url, name);
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
    };
    // 模拟点击下载
    const downloadImg = (href, name) => {
      let eleLink = document.createElement('a');
      eleLink.download = name;
      eleLink.href = href;
      eleLink.click();
      eleLink.remove();
    };

    // 获取详情
    const handleGetDeviceInfo = async () => {
      try {
        await getDeviceDetailAction({
          deviceId: deviceId.value,
        });
        handleSelectItem(
          businessStatus.find(
            (item) => item.value === deviceDetail.value.isSell
          )
        );
      } catch (error) {
        console.log('error', error);
      }
    };
    // 菜单点击
    const handleClickMenu = (menu) => {
      let query = {};
      switch (menu.path) {
        case 'autoCoin':
          workCoin.value = deviceDetail.value.workCoin;
          isShowAutoCionPop.value = true;
          console.log('自动投币', isShowAutoCionPop.value);
          break;
        case 'price':
          console.log('启动单价');
          dialogTitle.value = '启动单价';
          workCoin.value = deviceDetail.value.workCoin;
          isShowDialog.value = true;
          break;
        case 'qrcode':
          console.log('生成二维码');
          isShowPop.value = true;
          nextTick(() => {
            createQrcode();
          });
          break;
        case 'unbind':
          console.log('解绑');
          dialogTitle.value = '确定解绑设备？';
          isShowDialog.value = true;
          break;
        case 'business':
          console.log('营业状态');
          isShowBussinessPop.value = true;
          break;

        default:
          console.log('deviceDetail', deviceDetail);
          menu.query.forEach((k) => {
            if (k === 'searchText') {
              query['searchText'] = deviceDetail['value']['deviceCode'];
            } else {
              query[k] = deviceDetail['value'][k] || '';
            }
          });
          router.push({
            path: menu.path,
            query,
          });
          break;
      }
    };
    // 关闭弹窗
    const handleDialogClose = (action) => {
      console.log('close', action);
      return new Promise((resolve) => {
        if (action === 'confirm') {
          if (dialogTitle.value === '启动单价') {
            resolve(updateWorkCoin());
          } else {
            resolve(unBindDevice());
          }
        } else {
          resolve(true);
        }
      });
    };
    // 更新启动单价api
    const updateWorkCoin = async () => {
      if (!/^[1-9][0-9]{0,1}$/.test(workCoin.value)) {
        Toast('请输入正确格式的启动单价');
        return Promise.resolve(false);
      }
      try {
        const { code } = await updateDeviceStartCoinApi({
          deviceId: deviceId.value,
          workCoin: workCoin.value,
        });
        if (code === 200) {
          Toast('修改成功!');
          // 修改状态之后请求数据覆盖
          getDeviceDetailAction({
            deviceId: deviceId.value,
          });
          return Promise.resolve(true);
        }
      } catch (error) {
        return Promise.resolve(false);
      }
    };
    // 解绑设备
    const unBindDevice = async () => {
      try {
        const { code } = await unBindMchDeviceApi({
          deviceId: deviceId.value,
        });
        if (code === 200) {
          Toast('解除绑定成功!');
          setTimeout(() => {
            router.replace({
              path: '/device/operator',
            });
          }, 300);
          return Promise.resolve(true);
        }
      } catch (error) {
        return Promise.resolve(false);
      }
    };
    // 选中某个选项
    const handleSelectItem = (item) => {
      businessStatus.forEach((p) => {
        if (item && item['value'] === p['value']) {
          p.select = true;
        } else {
          p.select = false;
        }
      });
    };
    // 提交
    const handleConfirmSubmit = async (state) => {
      console.log('selected.value', selectedBusiness.value);
      try {
        const isSell = state ? state : selectedBusiness.value[0]['value'];
        console.log('isSell', isSell);
        const { code } = await updateDeviceStateApi({
          deviceId: deviceId.value,
          isSell,
        });
        if (code === 200) {
          Toast('设置成功');
          handleGetDeviceInfo();
          isShowBussinessPop.value = false;
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    return {
      qrCode,
      isShowDialog,
      isShowPop,
      isShowBussinessPop,
      isShowAutoCionPop,
      actions,
      dialogTitle,
      workCoin,
      deviceImg,
      deviceDetail,
      businessStatus,
      handleClickMenu,
      downloadQrcode,
      handleSelectItem,
      qrcodeAndriodImg,
      Menus,
      handleDialogClose,
      handleConfirmSubmit,
      handleAutoCionPopSelect,
      handleAutoCionPopClose,
      handleAutoCionOpen,
    };
  },
});
</script>

<style lang="scss" scoped>
.info-top {
  line-height: 26px;
}

.info-name {
  margin: 8px 0 4px 0;
}

.menu-item {
  width: 25%;
}

.dialog-box {
  padding: 20px;

  .price-input {
    width: 100px;
    height: 36px;
    overflow: hidden;
    margin: 0 8px;

    input {
      border: none;
      width: 100%;
      height: 100%;
      padding: 0 12px;
    }
  }
}

.pop-content {
  // width: 300px;
  // height: 280px;
  // padding: 30px;
  box-sizing: border-box;

  .qrcode-img {
    padding: 30px 56px;
  }

  img {
    padding: 42px;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
  }
}

.bussiness-pop-content {
  height: 260px;
  position: relative;
}
</style>
