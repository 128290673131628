<template>
  <Page is-header>
    <van-sticky offset-top="1.174rem">
      <!-- 日期选择 -->
      <TimePick @change="handleTimeChange" />
      <!-- 搜索框 -->
      <Search
        v-model="searchText"
        placeholder="请输入设备编号或名称搜索"
        @on-search="getList"
      />
    </van-sticky>
    <div class="order-list-box content">
      <template v-if="recordList.length">
        <template v-for="record in recordList" :key="record.siteId">
          <SummaryItem :record-info="record" />
        </template>
      </template>
      <template v-else>
        <van-empty image="search" description="暂无投币汇总记录" />
      </template>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, onActivated } from "vue";
import TimePick from "@/components/TimePick";
import Search from "@/components/Search";
import SummaryItem from "./components/SummaryItem.vue";
import { useRoute } from "vue-router";
import moment from "moment";
import { getCoinRecordApi } from "@/api/order";
export default defineComponent({
  name: "CoinSummary",
  components: {
    TimePick,
    Search,
    SummaryItem,
  },
  setup() {
    const route = useRoute();
    const recordList = ref([]);
    const now = moment(new Date()).format("YYYY-MM-DD");
    const currentDate = ref(now);
    // 回显参数
    onActivated(() => {
      console.log("父组件onActivated");
      searchText.value = route.query.deviceCode || "";
      getList();
    });
    const searchText = ref("");
    const handleTimeChange = (time) => {
      console.log(time);
      currentDate.value = time;
      getList();
    };
    // 请求列表接口
    const getList = async () => {
      console.log("getOrderListgetOrderList");
      recordList.value = [];
      try {
        const { code, content } = await getCoinRecordApi({
          searchText: searchText.value,
          beginTime: currentDate.value + " 00:00:00",
          endTime: currentDate.value + " 23:59:59",
        });
        if (code === 200) {
          // console.log("content", content);
          recordList.value = content;
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    return {
      searchText,
      recordList,
      getList,
      handleTimeChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-item-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 15px;
}
.filter-item-value {
  padding-bottom: 12px;
  :deep(.van-search) {
    padding: 0;
  }
}
.filter-item-tag {
  min-width: 74px;
  height: 32px;
  background-color: #f0f2f5;
  border-radius: 4px;
  color: #373838;
  margin: 0 10px 8px 0;
  font-size: 14px;
  padding: 0 6px;
  box-sizing: border-box;
}
.selected {
  background-color: rgba(2, 122, 255, 0.2);
  color: #027aff;
}
</style>
