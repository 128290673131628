<template>
  <van-popup
    :key="domKey"
    :show="visible"
    teleport="body"
    position="right"
    :lock-scroll="true"
    :close-on-popstate="true"
    :close-on-click-overlay="false"
    @click-overlay="onClose"
  >
    <div class="pop-box">
      <div class="pop-content">
        <slot name="content" />
      </div>
      <div class="pop-footer u-flex u-row-between">
        <van-button plain round color="#027AFF" @click="handleAction(-1)"
          >重置</van-button
        >
        <van-button round type="primary" @click="handleAction(1)"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import { defineComponent, onDeactivated } from "vue";
export default defineComponent({
  name: "PopRight",
  props: ["domKey", "visible"],
  emits: ["update:visible", "action"],
  setup(props, { emit }) {
    console.log(props);
    onDeactivated(() => {
      onClose();
    });
    const onClose = () => {
      emit("update:visible", false);
    };
    const handleAction = (type) => {
      emit("action", type);
    };
    return {
      onClose,
      handleAction,
    };
  },
});
</script>

<style lang="scss" scoped>
.pop-box {
  width: 75vw;
  height: 100vh;
}
.pop-content {
  height: calc(100vh - 64px);
  overflow-y: scroll;
  padding: 12px;
}
.pop-footer {
  height: 64px;
  padding: 12px;
  button {
    width: 48%;
  }
}
</style>
