<template>
  <Page is-header>
    <van-form ref="GiftForm" validate-trigger="onSubmit">
      <van-field
        v-model="deviceCode"
        name="deviceCode"
        label="设备编号"
        placeholder="请输入设备名称"
        readonly
      />
      <van-field
        v-model="giftForm.skuName"
        is-link
        readonly
        name="stock"
        label="商品名称"
        placeholder="请选择商品名称"
        :rules="[{ required: true, message: '请选择商品' }]"
        @click="handleShowPick"
      />
      <van-field
        v-model="giftForm.stock"
        name="stock"
        label="商品库存"
        type="digit"
        placeholder="请输入商品库存"
        :rules="[
          {
            required: true,
            message: '请输入0-999范围内的商品库存',
            trigger: 'onBlur',
            pattern: /^\d{0,3}$/,
          },
        ]"
      />
    </van-form>

    <div class="u-m-t-16 content">
      <van-button
        :loading="loading"
        loading-text="请稍后..."
        round
        type="primary"
        @click="handleSubmitSave"
      >
        保存
      </van-button>
    </div>

    <!-- 商品选择 -->
    <PopBottomList
      v-model:visible="isShowPop"
      pop-type="goods"
      :default-value="[giftForm.skuId]"
      :multiple="false"
      @save="handleSaveGoods"
    />
  </Page>
</template>

<script>
import { defineComponent, ref, reactive, onActivated } from "vue";
import { useRoute, useRouter } from "vue-router";
import PopBottomList from "@/components/PopBottomList.vue";
import { setDeviceGiftApi, getDeviceGiftApi } from "@/api/device";
import { Toast } from "vant";
export default defineComponent({
  name: "DeviceGift",
  components: {
    PopBottomList,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const GiftForm = ref(null);
    const isShowPop = ref(false);
    const deviceCode = ref("");
    const loading = ref(false);
    const giftForm = reactive({
      id: "",
      skuName: "",
      stock: "",
      deviceId: "",
      skuId: "",
      skuPicUrl: "",
    });
    onActivated(() => {
      Object.keys(giftForm).forEach((k) => {
        giftForm[k] = route.query[k] || "";
      });
      deviceCode.value = route.query.deviceCode;
      getDeviceGiftInfo();
    });
    // 获取信息回显
    const getDeviceGiftInfo = async () => {
      try {
        const { code, content } = await getDeviceGiftApi({
          deviceId: giftForm.deviceId,
        });
        if (code === 200) {
          console.log("content", content);
          if (!content) return false;
          Object.keys(giftForm).forEach((k) => {
            if (content[k] == null) {
              giftForm[k] = route.query[k] || "";
            } else {
              giftForm[k] = content[k];
            }
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const handleShowPick = () => {
      isShowPop.value = true;
    };
    const handleSaveGoods = (selected) => {
      console.log(selected);
      giftForm.skuName = selected[0].skuName;
      giftForm.skuId = selected[0].skuId;
      giftForm.skuPicUrl = selected[0].skuPicUrl;
      isShowPop.value = false;
    };
    const handleSubmitSave = () => {
      GiftForm.value
        .validate()
        .then(async () => {
          loading.value = true;
          try {
            const { code } = await setDeviceGiftApi({
              ...giftForm,
            });
            if (code === 200) {
              Toast("设置成功");
              loading.value = false;
              router.back();
            }
          } catch (error) {
            console.log("error", error);
            loading.value = false;
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    };
    return {
      GiftForm,
      giftForm,
      loading,
      isShowPop,
      deviceCode,
      handleShowPick,
      handleSaveGoods,
      handleSubmitSave,
    };
  },
});
</script>

<style lang="scss" scoped></style>
