<template>
  <Page is-header>
    <van-notice-bar
      left-icon="volume-o"
      text="仅支持对状态为已注册的设备进行启动、硬件设置等操作。"
    />
    <div v-if="deviceDetail" class="u-p-14 u-flex bg-white u-col-top">
      <van-image radius="4" width="72px" height="72px" :src="deviceImg" />
      <div class="u-flex-1 u-m-l-12">
        <div class="info-top u-flex">
          <!-- 信号 -->
          <SignalIcon
            :online="deviceDetail.onlineState"
            :signal="deviceDetail.networkSignal"
          />
          <div class="u-m-l-6">
            <Tag :text="['', '娃', '兑', '艺'][deviceDetail.deviceType]" />
          </div>
          <div class="u-m-l-6 u-m-r-6">
            <Tag
              bg="#999999"
              :text="['', '脉', '串'][deviceDetail.communicationMethod]"
            />
          </div>
          <span>{{ deviceDetail.deviceCode }}</span>
        </div>
        <p class="info-top u-content-color u-m-t-12 u-font-14">
          {{ ["已注册", "已激活", "已回收"][deviceDetail.activeState] }}
        </p>
        <p class="info-top u-content-color u-font-14">
          主控型号:{{ deviceDetail.productName }}
        </p>
      </div>
    </div>
    <!-- 全部功能 -->
    <div class="bg-white u-m-t-12 u-p-t-12 u-p-b-12">
      <p class="u-main-color u-weight-500 u-p-l-12">全部功能</p>
      <div class="u-flex u-flex-wrap">
        <div
          v-for="menu in Menus"
          :key="menu.path"
          class="menu-item u-flex u-flex-column u-m-t-16"
          @click="handleClickMenu(menu)"
        >
          <van-icon
            class-prefix="iconfont"
            :name="menu.icon"
            size="32"
            :color="menu.color"
          />
          <span class="u-main-color u-font-13 u-content-color u-m-t-10">
            {{ menu.name }}
          </span>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, onActivated, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { VendorDeviceDetailMenus } from "../const";
import { useGetters, useActions } from "@/hooks/useVuex";
import Tag from "@/components/Tag.vue";
import SignalIcon from "../components/SignalIcon.vue";
import { Toast } from "vant";
export default defineComponent({
  name: "VendorDeviceDetail",
  components: {
    Tag,
    SignalIcon,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const deviceId = ref("");
    const { getDeviceDetailAction } = useActions("device", [
      "getDeviceDetailAction",
    ]);
    const { deviceDetail } = useGetters("device", ["deviceDetail"]);
    // 请求
    onActivated(() => {
      deviceId.value = route.query.deviceId;
      getDeviceDetailAction({
        deviceId: deviceId.value,
      });
    });
    // 设备图片
    const deviceImg = computed(() => {
      return (
        deviceDetail.value &&
        deviceDetail.value.deviceType &&
        require(`@/assets/images/ic_device_${deviceDetail.value.deviceType}.png`)
      );
    });
    // 动态生成菜单
    const Menus = computed(() => {
      return VendorDeviceDetailMenus.filter((m) => m.show(deviceDetail.value));
    });
    // 菜单点击
    const handleClickMenu = (menu) => {
      if (deviceDetail.value.activeState) {
        // 0:已注册，1:已激活，2:已回收
        Toast("设备不支持当前操作");
        return false;
      }
      let query = {};
      console.log("deviceDetail", deviceDetail);
      menu.query.forEach((k) => {
        query[k] = deviceDetail["value"][k] || "";
      });
      router.push({
        path: menu.path,
        query,
      });
    };
    return {
      deviceImg,
      deviceDetail,
      handleClickMenu,
      Menus,
    };
  },
});
</script>

<style lang="scss" scoped>
.info-top {
  line-height: 26px;
}
.menu-item {
  width: 25%;
}
</style>
