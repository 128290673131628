<template>
  <div class="bg-white u-radius-4 u-m-t-12">
    <div class="u-p-12 van-hairline--bottom van-ellipsis u-weight-500">
      {{ recordInfo.siteName }}
    </div>
    <div
      v-for="device in recordInfo.deviceList"
      :key="device.deviceCode"
      class="u-p-12 van-hairline--bottom"
    >
      <p class="u-font-15 u-flex u-row-between">
        <span class="van-ellipsis u-flex-1 u-m-r-12"
          >{{ device.deviceCode }}-{{ device.deviceName }}</span
        >
        <span>{{ device.count }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "CoinItem",
  props: ["recordInfo"],
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
