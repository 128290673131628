<template>
  <van-popup
    :show="visible"
    teleport="body"
    class="pop-bottom"
    position="bottom"
    :lock-scroll="true"
    :close-on-popstate="true"
    :close-on-click-overlay="false"
    @click-overlay="close"
  >
    <div class="pop-content">
      <div v-if="header && !$slots.header" class="u-flex content">
        <div class="header-item" @click="close">取消</div>
        <div class="header-item u-text-center u-main-color">
          {{ $attrs.title }}
        </div>
        <div
          class="header-item u-flex u-row-right u-theme-color"
          @click="confirm"
        >
          确认
        </div>
      </div>
      <slot name="header" />
      <slot />
    </div>
  </van-popup>
</template>

<script>
import { defineComponent, onDeactivated } from "vue";
export default defineComponent({
  name: "Pick",
  props: {
    visible: {
      require: true,
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:visible", "confirm"],
  setup(props, { emit }) {
    onDeactivated(() => {
      close();
    });
    const close = () => {
      emit("update:visible", false);
    };
    const confirm = () => {
      emit("confirm");
    };
    return {
      close,
      confirm,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-item {
  width: 33.3%;
  flex: 1;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}
</style>
