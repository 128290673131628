<template>
  <van-icon
    v-bind="$attrs"
    class-prefix="iconfont"
    :name="iconOption.name"
    size="20"
    :color="iconOption.color"
  />
</template>

<script>
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "SignalIcon",
  props: {
    // 信号强度
    signal: {
      type: [String, Number],
      default: "0",
    },
    // 是否在线
    online: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const iconOption = computed(() => {
      let iconOption = {
        name: "",
        color: "",
      };
      if (props.online) {
        if (props.signal <= 16) {
          // 微弱
          iconOption.name = "xinhao1";
          iconOption.color = "#28CE3E";
        } else if (props.signal > 16 && props.signal <= 21) {
          // 中等
          iconOption.name = "xinhao2";
          iconOption.color = "#28CE3E";
        } else if (props.signal > 21) {
          // 强
          iconOption.name = "xinhao3";
          iconOption.color = "#28CE3E";
        }
      } else {
        iconOption.name = "xinhao0";
        iconOption.color = "#D1D1D1";
      }
      return iconOption;
    });
    return {
      iconOption,
    };
  },
});
</script>
