import { useAuth } from '@/hooks/useAuth';
export const options = [
  {
    label: '在离线状态',
    type: 'tag',
    key: 'onlineState',
    value: [
      {
        label: '全部',
        value: '',
        select: true,
      },
      {
        label: '在线',
        value: '1',
        select: false,
      },
      {
        label: '离线',
        value: '0',
        select: false,
      },
    ],
  },
  {
    label: '设备类型',
    type: 'tag',
    key: 'deviceType',
    value: [
      {
        label: '全部',
        value: '',
        select: true,
      },
      {
        label: '娃娃机',
        value: '1',
        select: false,
      },
      {
        label: '兑币机',
        value: '2',
        select: false,
      },
      {
        label: '游艺机',
        value: '3',
        select: false,
      },
    ],
  },
  {
    label: '营业状态',
    type: 'tag',
    key: 'isSell',
    value: [
      {
        label: '全部',
        value: '',
        select: true,
      },
      {
        label: '正常',
        value: '1',
        select: false,
      },
      {
        label: '停售',
        value: '0',
        select: false,
      },
      {
        label: '故障禁用',
        value: '2',
        select: false,
      },
    ],
  },
];

export const DeviceTypes = [
  {
    text: '娃娃机',
    value: '1',
  },
  {
    text: '兑币机',
    value: '2',
  },
  {
    text: '游艺机',
    value: '3',
  },
];
// 通讯方式
export const CommunicationTypes = [
  {
    text: '硬件协议',
    value: 2,
  },
  {
    text: '脉冲通讯',
    value: 1,
  },
];
// 协议
export const ProtocolTypes = [
  {
    text: '协议一',
    value: 1,
  },
];

export const DeviceDetailMenus = [
  {
    name: '启动单价',
    path: 'price',
    icon: 'qidongdanjia',
    color: '#09c099',
    query: [],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 3].includes(info.deviceType) && useAuth('B0103');
    },
  },
  {
    name: '基本信息',
    path: '/device/setting/info',
    icon: 'jibenxinxi',
    color: '#f2ae42',
    query: ['deviceId', 'deviceName', 'siteId', 'siteName', 'sortNo'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('B0104');
    },
  },
  {
    name: '支付订单',
    path: '/order/list',
    icon: 'zhifudingdan1',
    color: '#f1594c',
    query: ['searchText'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('B0201,B020101');
    },
  },
  {
    name: '投币记录',
    path: '/device/record/coin-record',
    icon: 'toubijilu',
    color: '#027AFF',
    query: ['deviceCode'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 3].includes(info.deviceType) && useAuth('B0203,B020301');
    },
  },
  {
    name: '礼品设置',
    path: '/device/setting/gift',
    icon: 'lipinshezhi',
    color: '#f1594c',
    query: ['deviceId', 'deviceCode'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1].includes(info.deviceType) && useAuth('B0105');
    },
  },
  {
    name: '生成二维码',
    path: 'qrcode',
    color: '#027aff',
    icon: 'shengchengerweima',
    query: [],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType);
    },
  },
  {
    name: '解绑设备',
    path: 'unbind',
    color: '#f2ae42',
    icon: 'jiebangshebei',
    query: [],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('B0102');
    },
  },
  {
    name: '投币汇总',
    path: '/device/record/coin-summary',
    icon: 'toubihuizong',
    color: '#09C099',
    query: ['deviceCode'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 3].includes(info.deviceType) && useAuth('B0203,B020301');
    },
  },
  {
    name: '礼品记录',
    path: '/device/record/gift-record',
    icon: 'lipinjilu',
    color: '#F1594C',
    query: ['deviceCode'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1].includes(info.deviceType) && useAuth('B0204,B020401');
    },
  },
  {
    name: '硬件设置',
    path: '/device/setting/hardware',
    icon: 'yingjianshezhi',
    color: '#027aff',
    query: ['deviceCode', 'deviceId'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('B0106');
    },
  },
  {
    name: '启动测试',
    path: '/device/setting/start-test',
    icon: 'qidongceshi',
    color: '#09C099',
    query: ['deviceCode', 'deviceId'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('B0107');
    },
  },
  {
    name: '营业状态',
    path: 'business',
    icon: 'yingye',
    color: '#f2ae42',
    query: ['deviceCode', 'deviceId'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('B0108');
    },
  },
  {
    name: '机器广告',
    path: '/advertisement/device',
    icon: 'jiqiguanggao',
    color: '#09c099',
    query: ['deviceId'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return (
        [2].includes(info.deviceType) && info.androidId !== 'X' && useAuth('')
      );
    },
  },
  {
    name: '自动投币',
    path: 'autoCoin',
    icon: 'zidongtoubi',
    color: '#027aff',
    query: ['deviceCode', 'deviceId'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [3].includes(info.deviceType) && useAuth('B0109');
    },
  },
];

export const vendorOptions = [
  {
    label: '设备类型',
    type: 'tag',
    key: 'deviceType',
    value: [
      {
        label: '全部',
        value: '',
        select: true,
      },
      {
        label: '娃娃机',
        value: '1',
        select: false,
      },
      {
        label: '兑币机',
        value: '2',
        select: false,
      },
      {
        label: '游艺机',
        value: '3',
        select: false,
      },
    ],
  },
  {
    label: '注册状态',
    type: 'tag',
    key: 'activeState',
    value: [
      {
        label: '全部',
        value: '',
        select: true,
      },
      {
        label: '已注册',
        value: '0',
        select: false,
      },
      {
        label: '已激活',
        value: '1',
        select: false,
      },
      {
        label: '已回收',
        value: '2',
        select: false,
      },
    ],
  },
];
export const VendorDeviceDetailMenus = [
  {
    name: '硬件设置',
    path: '/device/setting/hardware',
    icon: 'yingjianshezhi',
    color: '#027aff',
    query: ['deviceCode', 'deviceId'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('C0103');
    },
  },
  {
    name: '启动测试',
    path: '/device/setting/start-test',
    icon: 'qidongceshi',
    color: '#09C099',
    query: ['deviceCode', 'deviceId'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('C0104');
    },
  },
  {
    name: '更换主控',
    path: '/device/setting/control',
    icon: 'genghuanzhukong',
    color: '#F2AE42',
    query: ['productName', 'deviceId', 'deviceType'],
    show: (info) => {
      // 1：娃娃机显示 2：兑币机显示 3: 游艺机显示
      return [1, 2, 3].includes(info.deviceType) && useAuth('C0105');
    },
  },
];

export const BusinessStatus = [
  { label: '正常', value: 1, select: true },
  { label: '停售', value: 0, select: false },
  { label: '故障', value: 2, select: false },
];
