<template>
  <div class="bg-white u-radius-4 u-p-12 u-m-t-12 record-item-box">
    <div class="coin-type-box u-flex">
      <span>{{ ["", "线上币", "实体币"][recordInfo.coinType] }}:</span>
      <span>{{ recordInfo.workCoin }}币</span>
      <span>1次</span>
    </div>
    <p class="record-device u-main-color u-font-15 van-ellipsis">
      {{ recordInfo.deviceCode }}-{{ recordInfo.deviceName }}
    </p>
    <p class="u-main-color u-font-15">
      用户ID: {{ recordInfo.consumerUserId || "--" }}
    </p>
    <p class="status-box">
      <span
        v-for="(status, idx) in Status"
        :key="idx"
        class="record-status"
        :class="{
          success: recordInfo[status] != '0',
          error: recordInfo[status] == '0',
        }"
      >
        {{ StatusMap[status][recordInfo[status]] }}
      </span>
    </p>
    <p class="u-flex u-row-between u-font-15">
      <span class="u-tips-color">{{ recordInfo.gmtCreate }}</span>
      <span style="color: #f56c6c">{{ recordInfo.coinCount }}币</span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { amountFmt } from "@/utils/format";
// const Status = ["startState", "coinState", "hitGift"];
// const StatusMap = {
//   startState: ["启动失败", "启动成功"],
//   coinState: ["消费失败", "消费成功"],
//   hitGift: ["未中礼品", "命中礼品", "未知"],
// };
const Status = ["coinState"];
const StatusMap = {
  coinState: ["消费失败", "消费成功", "退币成功"],
};
export default defineComponent({
  name: "CoinItem",
  props: ["recordInfo"],
  setup() {
    return {
      Status,
      StatusMap,
      amountFmt,
    };
  },
});
</script>

<style lang="scss" scoped>
.record-item-box {
  position: relative;
  overflow: hidden;
  line-height: 20px;
  p {
    padding: 4px 0;
    word-break: break-all;
  }
  .coin-type-box {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 8px;
    color: #ffffff;
    font-size: 11px;
    background-color: rgba(153, 153, 153, 0.8);
    border-radius: 12px 0 0 12px;
  }
  .record-device {
    width: 75%;
  }
}
.record-status {
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 12px;
  margin-right: 6px;
}
.success {
  background-color: #f0f2f5;
  color: #999999;
}
.error {
  background-color: rgba(245, 108, 108, 0.1);
  color: #f56c6c;
}
</style>
