<template>
  <div class="bg-white u-radius-4 u-m-t-12">
    <div class="u-p-12 van-hairline--bottom van-ellipsis u-weight-500">
      {{ recordInfo.siteName }}
    </div>
    <div
      v-for="device in recordInfo.deviceList"
      :key="device.deviceCode"
      class="u-p-12 van-hairline--bottom"
    >
      <p class="van-ellipsis u-font-15">
        {{ device.deviceCode }}-{{ device.deviceName }}
      </p>
      <p class="u-content-color u-m-t-12 u-font-14">
        <span class="u-m-r-12">线上币：{{ device.onLineCount }}</span>
        <span class="u-m-r-12">实体币：{{ device.realCount }}</span>
        <span>总数：{{ device.count }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "SummaryItem",
  props: ["recordInfo"],
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
